<template>
	<div class="tools">



		<v-card-title>
			<v-spacer></v-spacer>
			<!-- Justify to Right -->
			<v-btn class="ma-2 mt-4" outlined @click="flushDB" color="primary">
				Flush Products
			</v-btn>
		</v-card-title>



		<v-form>
			<vue-csv-import v-model="csv" url="/tools" :autoMatchFields="true" :map-fields="['NumPolice','DateCarteGrise','Millesime','DateMEC','Genre','Marque','7','Carr','Energie','PuissanceFiscale','NbPlaces','ModelComplet','CertEurotax','Kilometrage','QualificationKilometrage','16','couleur','18','prix','20','warranty','warranty2','prixInternet','24','25','26','sInitiale','28','29','30','31','32','33','34','35','IdGroupe','37','NomSociete','39','40','41','42','43','44','45','46','MarqueSecond','Version','49','50','51','52','NbrPortes','Boite','Co','TvaRecup','57','58','59','60','PuissanceDin','NbrRapports','ConsoMoy','ddd1','65','66','67','68','Immat','70','71','72','vendeur','74','75','76','77','78','79','80','81','Option1','Option2','Option3','Option4','Option5','Option6','Option7','Option8','Option9','Option10','Option11','Option12','Option13','Option14','Option15','Option16','Option17','Option18','Option19','Option20','Option21','Option22','Option23','Option24','Option25','Option26','Option27','Option28','Option29','Option30','Option31','Option32','Destin','Site','Lieu','img1','img2','img3','img4','img5','img6','img7','img8','MontantDevis','TvaDevis','img9','img10','img11','img12','img13','img14','img15','134','nChassis','Option33','Option34','Option35','Option36','Option37','Option38','Option39','Option40','Option41','Option42','Option43','Option44','Option45','Option46','Option47','Option48','Option49','Option50','Option51','Option52','156','157','158','159','160','161','162','163','164','165','166','167','168','169']">

				<template slot="hasHeaders" slot-scope="{headers, toggle}">
					<label>
						<input type="checkbox" id="hasHeaders" :value="headers" @change="toggle">
						Le fichier possède sont propre header
					</label>
				</template>

				<template slot="error">
					File type is invalid ?
				</template>

				<!-- <template slot="thead">
					<tr>
						<th>My Fields</th>
						<th>Columns</th>
					</tr>
				</template> -->

				<template slot="next" slot-scope="{load}">
					<v-btn right class="ma-5 mt-4" color="success" text outlined @click.prevent="load">Load file</v-btn>
				</template>

				<template slot="submit" slot-scope="{submit}">
					<v-btn right class="ma-5 mt-4" color="success" text outlined @click.prevent="submit">Send</v-btn>
				</template>
			</vue-csv-import>
		</v-form>

		<v-simple-table v-if="csv">
			<template v-slot:default>
				<tbody>
					<tr v-for="voiture in csv" :key="voiture.NumPolice">
						<td>{{ voiture.NumPolice }}</td>
						<td>{{ voiture.Millesime }}</td>
						<td>{{ voiture.DateMEC}}</td>
						<td>{{ voiture.Marque }}</td>
						<td>{{ voiture.Carr }}</td>
						<td>{{ voiture.Energie }}</td>
						<td>{{ voiture.PuissanceFiscale }}</td>
						<td>{{ voiture.NbPlaces }}</td>
						<td>{{ voiture.ModelComplet }}</td>
						<td>{{ voiture.CertEurotax }}</td>
						<td>{{ voiture.Kilometrage }}</td>
						<td>{{ voiture.QualificationKilometrage }}</td>
						<td>{{ voiture.couleur }}</td>
						<td>{{ voiture.prix }}</td>
						<td>{{ voiture.warranty }}</td>
						<td>{{ voiture.warranty2 }}</td>
						<td>{{ voiture.prixInternet }}</td>
						<td>{{ voiture.sInitiale }}</td>
						<td>{{ voiture.IdGroupe }}</td>
						<td>{{ voiture.NomSociete }}</td>
						<td>{{ voiture.Marque2 }}</td>
						<td>{{ voiture.Version }}</td>
						<td>{{ voiture.NbrPortes }}</td>
						<td>{{ voiture.Boite }}</td>
						<td>{{ voiture.Co }}</td>
						<td>{{ voiture.TvaRecup }}</td>
						<td>{{ voiture.PuissanceDin }}</td>
						<td>{{ voiture.NbrRapports }}</td>
						<td>{{ voiture.ConsoMoy }}</td>
						<td>{{ voiture.ddd1 }}</td>
						<td>{{ voiture.Immat }}</td>
						<td>{{ voiture.vendeur }}</td>
						<td>{{ voiture.Option1 }}</td>
						<td>{{ voiture.Option2 }}</td>
						<td>{{ voiture.Option3 }}</td>
						<td>{{ voiture.Option4 }}</td>
						<td>{{ voiture.Option5 }}</td>
						<td>{{ voiture.Option6 }}</td>
						<td>{{ voiture.prix }}</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
	</div>
</template>

<script>
	import {
		VueCsvImport
	} from 'vue-csv-import';

	export default {
		head: {
			title: {
				inner: "Outils"
			}
		},
		components: {
			VueCsvImport
		},
		name: 'Outils',
		data() {
			return {
				file: '',
				csv: '',
				fichierNuls: 0,
			};
		},
		watch: {
			csv: function () {
				console.log(this.csv);

				var nbFichier = 0;

				this.csv.forEach((tempDocument) => {
					var document = {
						...tempDocument,
						Prix: tempDocument.prix,
						Kilométrage: tempDocument.Kilometrage,
						Année: tempDocument.Millesime,
						belongTo: "Kg6kVBaPnhiNGjPUU0Rj",
						type: "renault_car",
						status: 1
					};

					try {
						this.$db.collection("companies").doc(this.userInfo.company).collection("products").doc(document.NumPolice).set(document, { merge: true }).then(() => {
							nbFichier++;
							console.log(
								`Voiture ref ${document.NumPolice} ajoutée ! (${nbFichier}/${this.csv.length})`
							)
						});
					} catch (error) {
						this.fichierNuls++;

						console.log(document.NumPolice);
						console.log(document);

						console.error(error);
					}
				});

				console.error(
					`${this.fichierNuls} véhicules n'ont pas été importés, soit ${(100*this.fichierNuls) / this.csv.length}% d'echec`
				);
			}
		},
		methods: {
			flushDB() {
				this.$db.collection("companies").doc(this.userInfo.company).collection("products").get().then((res) => {

					res.forEach((document) => {
						if(document.data().type == "renault_car") this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(this.userInfo.company).collection("products").doc(document.id), "Tous les produits de SAS Lafay sont en cours de suppression.");
					});

				});
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot((doc) => {
				this.userInfo = doc.data();
			});
		}
	}
</script>
